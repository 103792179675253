/* eslint-disable sonarjs/no-duplicate-string */
import Fade from "components/animations/Fade";
import { ArrowRight } from "components/base/Arrow";
import { useBackground } from "components/base/layout/Background";
import Heading2 from "components/base/text/Heading2";
import { Footer } from "components/Footer";
import ProfileForm from "components/user/ProfileForm";
import useGames from "hooks/useGames";
import useProfile from "hooks/user/useProfile";
import useProfileUpdate from "hooks/user/useProfileUpdate";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { formatPrice } from "../../utils/number";

export const Profile = () => {
  useBackground("#f9f7f4");
  const { newProfile, isProfileLoading } = useProfile();
  const { updateProfile, isProfileUpdating, updateProfileErrors } = useProfileUpdate();
  const { games } = useGames();
  const navigate = useNavigate();

  if (!newProfile) return null;

  return (
    <PageWrapper delay={300}>
      <Box>
        <Heading2>Your Account</Heading2>
        <Separator />
        <ProfileForm
          onSubmit={v => updateProfile(v)}
          initialValues={newProfile}
          loading={isProfileLoading || isProfileUpdating}
          enableReinitialize={true}
          initialErrors={updateProfileErrors}
        />
      </Box>
      <Box>
        <Heading2>Your Subscriptions</Heading2>
        <Separator />
        {games?.map((game, i) => (
          <>
            <ItemContainer onClick={() => navigate("/profile/game/" + game.id)}>
              <div>
                <Bold>{game.name}</Bold>
                <p>{game.price ? "$" + formatPrice(game.price) : "FREE"}</p>
              </div>
              <ArrowRight />
            </ItemContainer>
            {i !== games.length - 1 && <Separator />}
          </>
        ))}
        {games?.length === 0 && <p>You don't have any active subscriptions</p>}
      </Box>
      <Footer />
    </PageWrapper>
  );
};

const PageWrapper = styled(Fade)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  min-height: 100dvh;
`;

const Box = styled.div`
  background: #eee7df;
  border-radius: 1rem;
  color: #241f18;
  max-width: 420px;
  padding: 1rem;
  width: fill-available;
  @media (min-width: 768px) {
    max-width: 720px;
  }
`;

const ItemContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  & > :first-child {
    flex: 1;
  }
`;

const Separator = styled.div`
  background: #ab997e;
  height: 1px;
  margin: 1rem 0;
`;

const Bold = styled.span`
  font-weight: bold;
`;

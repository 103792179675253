import Fade from "components/animations/Fade";
import EmptyContent from "components/base/EmptyContent";
import { useBackground } from "components/base/layout/Background";
import { Footer } from "components/Footer";
import Select from "components/form/Select/Select";
import JourneyList from "components/Journey/JourneyList";
import { useNavigateWithFade } from "components/PageRouter/PageRouter";
import useAnalyticsGroupUser from "hooks/analytics/useAnalyticsGroupUser";
import useAnalyticsGroupUserLabs from "hooks/analytics/useAnalyticsGroupUserLabs";
import useProfile from "hooks/user/useProfile";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

export default function AnalyticsPage() {
  useBackground("rgb(102,77,243)", true);
  const { userid, groupid } = useParams<{ userid: string; groupid: string }>();
  const [searchParams] = useSearchParams();
  const gameid = searchParams.get("gameid") || undefined;

  const navigate = useNavigate();
  const navigateWithFade = useNavigateWithFade();
  const { profile, isProfileLoading } = useProfile();
  const { journey } = useAnalyticsGroupUser({ userid });
  const { labs, isLabsLoading } = useAnalyticsGroupUserLabs(userid);

  useEffect(() => {
    if (!gameid && labs?.length) {
      navigate(`/analytics/group/${groupid}/user/${userid}?gameid=${labs[0].id}`, {
        replace: true,
      });
    }
  }, [gameid, groupid, labs, navigate, userid]);

  if (isLabsLoading || isProfileLoading || !profile) return null;

  if (!labs?.length) return <EmptyContent />;

  return (
    <Fade delay={100}>
      <LabSelect>
        <svg
          viewBox="0 0 64 65"
          fill="none"
          width={48}
          height={48}
          onClick={() => navigateWithFade(`/analytics/group/${groupid}`)}
          style={{ cursor: "pointer" }}
        >
          <rect y="0.5" width="64" height="64" rx="32" fill="#F9F7F4" />
          <path
            d="M36 20.5L24 32.5L36 44.5"
            stroke="#664DF3"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <Select
          value={gameid || ""}
          onChange={(e: any) =>
            navigate(
              `/analytics/group/${groupid}/user/${userid}?gameid=${e.target.value}`,
            )
          }
        >
          {labs?.sort().map((lab: any) => (
            <option key={lab.id} value={lab.id}>
              {lab.name}
            </option>
          ))}
        </Select>
      </LabSelect>
      {journey && (
        <div>
          <JourneyList journey={journey} isLoading={false} />
          <Footer />
        </div>
      )}
    </Fade>
  );
}

const LabSelect = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: center;
  left: 1rem;
  position: absolute;
  top: 1rem;
  z-index: 1;
  & > div {
    max-width: calc(100vw - 10rem);
  }
`;

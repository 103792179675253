import { Footer } from "components/Footer";
import JourneyHeader from "components/Journey/JourneyHeader";
import JourneyList from "components/Journey/JourneyList";
import { useGetUserGames } from "hooks/useGetUserGames";
import { useGetUserJourney } from "hooks/useGetUserJourney";

export const HomePage = () => {
  const { journey, isJourneyLoading } = useGetUserJourney();
  const { games } = useGetUserGames();
  const lastOpenCycle = games?.data?.[0];

  return (
    <div>
      {!!lastOpenCycle && !!journey?.length && <JourneyHeader cycle={lastOpenCycle} />}
      <JourneyList journey={journey} isLoading={isJourneyLoading} />
      <Footer />
    </div>
  );
};

import Fade from "components/animations/Fade";
import { SnackBarProvider } from "components/base/Alert/Alert";
import Menu from "components/base/Menu";
import useIsAuth from "hooks/user/useIsAuth";
import Analytics from "pages/Analytics";
import AnalyticsGroup from "pages/AnalyticsGroup";
import AnalyticsGroupUser from "pages/AnalyticsGroupUser";
import { GetTokenPage } from "pages/GetTokenPage";
import { HomePage } from "pages/HomePage";
import Labs from "pages/Labs";
import { Login } from "pages/Login";
import { Profile } from "pages/Profile";
import { ProfileGame } from "pages/Profile/ProfileGame";
import { ProfileGameUnsubscribe } from "pages/Profile/ProfileGameUnsubscribe";
import Register from "pages/Register";
import { createContext, useContext, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

const RouterContext = createContext<any>(null);

const useNavigateWithFadeContext = () => {
  const [exiting, setExiting] = useState(false);
  const navigate = useNavigate();
  const navigateWithFade = (to: string) => {
    setExiting(true);
    setTimeout(() => {
      setExiting(false);
      navigate(to);
    }, 350);
  };

  return { navigateWithFade, exiting };
};

export const useNavigateWithFade = () => useContext(RouterContext);

export const PageRouter = () => {
  const isAuth = useIsAuth();
  const { pathname } = useLocation();
  const { navigateWithFade, exiting } = useNavigateWithFadeContext() || {};

  return (
    <SnackBarProvider>
      <RouterContext.Provider value={navigateWithFade}>
        {isAuth && pathname !== "/api/magic_login/" && <Menu />}
        <Fade visible={!exiting}>
          <Routes>
            <Route path="/api/magic_login" element={<GetTokenPage />} />
            <Route path="/onboarding/:poolSlug" element={<Register />} />
            {!isAuth ? (
              <>
                <Route path="/login" element={<Login />} />
                <Route path={"/*"} element={<Navigate to="/login" replace />} />
              </>
            ) : (
              <>
                <Route path={"/"} element={<HomePage />} />
                <Route path={"/labs"} element={<Labs />} />
                <Route path={"/analytics"} element={<Analytics />} />
                <Route path={"/analytics/group"} element={<AnalyticsGroup />} />
                <Route path="/analytics/:gameid" element={<Analytics />} />

                <Route
                  path={"/analytics/group/:groupid"}
                  element={<AnalyticsGroup />}
                />
                <Route
                  path={"/analytics/group/:groupid/user/:userid"}
                  element={<AnalyticsGroupUser />}
                />
                <Route path={"/profile"} element={<Profile />} />
                <Route path={"/profile/game/:gameid"} element={<ProfileGame />} />
                <Route
                  path={"/profile/game/:gameid/unsubscribe"}
                  element={<ProfileGameUnsubscribe />}
                />
                <Route path={"/*"} element={<Navigate to="/" replace />} />
              </>
            )}
          </Routes>
        </Fade>
      </RouterContext.Provider>
    </SnackBarProvider>
  );
};
